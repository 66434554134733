
/**
 * フォーカストラップ
 * @param {string} parent 
 */
function focusTrap(parent) {
  const focusableElementsString = 'a[href], area[href], input:not([disabled]), button:not([disabled]), object, embed, [tabindex="0"], [contenteditable]';
  $.each($(parent), function(i,e){
      var elements = $(e).find(focusableElementsString);
      var el = {
          first: elements[0],
          last: elements[elements.length -1]
      }
      $(el.last).focusout(function(){
          $(el.first).focus();
      })
  })
}
focusTrap('#focus-trap');