/*--------------------------
    scroll
----------------------------*/

/* アンカーリンクのスムーススクロール */
$('a[href^="#"]').on('click', function() {
  var speed = 400;
  var offset = 0;
  var $this = $(this);
  var href = $this.attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top - offset
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false
});


/**
 * スクロールするとフェードイン
 * @param {selector} e 
 */
function scroll_to_fadein(e) {
  var $elm = $(e);
  $elm.hide();
  $(window).on('scroll load',function () {
    if ($(this).scrollTop() > 200) {
      $elm.fadeIn();
    } else {
      $elm.fadeOut();
    }
  });
}
scroll_to_fadein('.js-position');


/**
 * スクロールするとフェードイン + ある要素が見えたらSticky
 * @param {String} element 
 * @param {String} target 
 * @param {Nunber} offset 
 */
function scroll_to_fadein_sticky(element,target,offset) {
  var $elm = $(element);
  var $btn = $elm.children();
  var offset = typeof offset !== 'undefined' ?  offset : 0;
  $btn.hide();
  $(window).on('scroll',function () {
      if ($(this).scrollTop() > 50) {
          $btn.fadeIn();
      } else {
          $btn.fadeOut();
      }
      var $w = $(window);
      var $stickyTarget = $(target).offset().top;
      var $trigger = $w.scrollTop() + $w.height();
      var sticky_scrolled = $trigger > $stickyTarget ? true : false;
      if(sticky_scrolled) {
          var $stickyPosition = $trigger - $stickyTarget + offset;
          //$elm.css('bottom', $stickyPosition);
          $elm.addClass('top_position');
      } else {
          //$elm.attr('style','');
          $elm.removeClass('top_position');
      }
  });
}
scroll_to_fadein_sticky('.c-scroll-top','.l-footer');


/**
 * scrollで要素が画面頂点に到達したらclassを追加
 * @param {selector} el 
 * @param {string} classname 
 */
function scrollTop2addClass(el,classname) {
  var $element = $(el),
      default_position = $element.offset().top,
      offset = 0;
  if($(window).scrollTop() > default_position + offset) {
      $element.addClass(classname);
  } else {
      $element.removeClass(classname);
  }
}